<template>
  <div></div>
</template>

<script>
export default {
  name: "LoginAuto",
  created() {
    let username = this.$route.params.username
    let password = this.$route.params.password
    let cpfcnpj = this.$route.params.cpfcnpj

    if (username !== undefined && password !== undefined) {
      this.$router.push({name: 'login', params: {username, password}})
    } else if (cpfcnpj !== undefined) {
      this.$router.push({name: 'login', params: {cpfcnpj}})
    } else {
      this.$router.push('login')
    }
  },
}
</script>

<style scoped>

</style>