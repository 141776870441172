<template>
  <div>
    <table class="table">
      <tr v-for="boleto in boletos" :key="boleto.id">
        <td class="align-middle pr-0">
          <a :href="urlBoleto(boleto)" class="mx-auto" target="_blank">
            <div class="icon-circle p-1 text-center">
              <i class="fa fa-barcode align-middle"></i>
            </div>
          </a>
        </td>
        <td>
          <a :href="urlBoleto(boleto)" class="mx-auto" target="_blank">
            <div>
              <small>Vencimento</small>
              <br>
              {{ new Intl.DateTimeFormat('pt-BR').format(Date.parse(boleto.data_vencimento + "T00:00")) }}
            </div>
            <div class="d-block d-lg-none">
              <small>Valor</small>
              <br>
              {{ new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(boleto.valor) }}
            </div>
          </a>
        </td>
        <td class="d-none d-lg-inline-block">
          <a :href="urlBoleto(boleto)" class="mx-auto" target="_blank">
            <div>
              <small>Valor</small>
              <br>
              {{ new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(boleto.valor) }}
            </div>
          </a>
        </td>
        <td>
          <b-button variant="primary" block size="sm" v-if="!isPago" @click="clickPagar(boleto)" class="text-white">
            Pagar
          </b-button>
          <a v-if="boleto.qrcode_img" @click="clickPagarPix(boleto)"
             class="btn btn-success btn-sm btn-block text-white">Pagar com Pix</a>
          <a v-if="boleto.url_nota_fiscal" :href="boleto.url_nota_fiscal" class="btn btn-primary btn-sm btn-block"
             target="_blank">Nota Fiscal</a>
          <a v-if="boleto.link_recibo && isPago" :href="boleto.link_recibo"
             class="btn btn-info btn-sm pt-1 px-1 btn-block"
             target="_blank">Recibo</a>
        </td>
      </tr>
    </table>
    <fatura-pix ref="fatura-pix"></fatura-pix>
    <fatura ref="fatura"></fatura>
  </div>
</template>

<script>
import FaturaPix from "@/components/FaturaPix"
import Fatura from "@/components/Fatura"

export default {
  name: "Faturas",
  components: {
    FaturaPix,
    Fatura,
  },
  data() {
    return {
      faturaPix: null
    }
  },
  props: {
    boletos: Array,
    isPago: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    urlBoleto: function (boleto) {
      return 'https://link.receitanet.net/boleto/' + boleto.id
    },
    clickPagarPix: function (boleto) {
      this.$refs['fatura-pix'].$emit('show', boleto)
    },
    clickPagar: function (boleto) {
      this.$refs['fatura'].$emit('show', boleto)
    }
  },
}
</script>

<style scoped>
table td, table th {
  border-top: none;
}
</style>
