<template>
  <div>
    <b-button @click="show" variant="success">Respostas</b-button>
    <b-modal ref="modal-replies" title="Respostas" size="lg" ok-only @shown="shown">

      <div class="container" v-if="isLoad">
        <div class="row row-cols-1">
          <div class="col text-center">
            <span class="fa fa-refresh fa-spin text-white"></span>
          </div>
          <div class="col text-center">
            <span class="text-white">Carregando...</span>
          </div>
        </div>
      </div>
      <div v-else>
        <b-card ref="chat" class="chat">
          <b-card v-if="!respostas.length">
            <p class="mb-0">Nenhuma respostas localizada.</p>
          </b-card>
          <div v-for="resposta in respostas" :key="resposta.datahora" v-else>
            <div class="row" v-if="resposta.tipo === 2">
              <div class="col-9">
                <b-card :header="resposta.atendente" :sub-title="datahora(resposta.datahora)">
                  {{ resposta.resposta }}
                </b-card>
              </div>
            </div>
            <div class="row" v-if="resposta.tipo === 1">
              <div class="col-9 offset-3">
                <b-card bg-variant="default" header-bg-variant="default" text-variant="white" header="Cliente"
                        :sub-title="datahora(resposta.datahora)">
                  {{ resposta.resposta }}
                </b-card>
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <b-card v-if="isAberto">
        <div class="form-group">
          <textarea v-model="fieldResposta" class="form-control mb-2" rows="4"></textarea>
          <b-button variant="info" @click="clickStore" class="mb--4">Responder</b-button>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ChamadosRespostas",
  props: ['idSuporte', 'isAberto'],
  data: function () {
    return {
      error: null,
      isLoad: true,
      fieldResposta: '',

      respostas: [
        // {"resposta":"Teste de Resposta","datahora":"2020-09-26 18:07:18","tipo":2,"atendente":"RECEITANET"}
        // {"resposta":"Teste de Resposta do Cliente","datahora":"2020-09-26 18:07:34","tipo":1}
      ],
    }
  },
  watch: {
    isLoad: function (val) {
      if (!val) {
        setTimeout(function () {
          try {
            this.$refs['chat'].scrollTo(0, 99999)
          } catch {
            val = 0
          }
        }.bind(this), 100)
      }
    },
  },
  methods: {
    datahora: function (str) {
      try {
        return Intl.DateTimeFormat('pt-BR')
            .format(Date.parse(str))
      } catch {
        return str;
      }
    },
    loadRespostas: function () {
      this.isLoad = true
      this.$axios.get(process.env.VUE_APP_CENTRAL_API_URL + "chamados/respostas/" + this.idSuporte)
          .then(res => {
            this.isLoad = false
            this.respostas = res.data
          })
          .catch(() => {
            this.isLoad = false
            this.error = 'Falha ao carregar lista de respostas. Tente novamente.'
          })
    },
    show: function () {
      this.$refs['modal-replies'].show()
    },
    clickStore: function () {
      if (this.fieldResposta.length > 1) {
        this.error = 'Informe uma Resposta.'

        this.$axios.post(process.env.VUE_APP_CENTRAL_API_URL + 'chamados/respostas/' + this.idSuporte, {
          resposta: this.fieldResposta,
        })
            .then(() => {
              this.loadRespostas()
              this.fieldResposta = ''
            })
            .catch(() => {
              this.error = 'Falha ao adicionar resposta. Tente novamente.'
            })
      }
    },
    shown: function () {
      this.isLoad = true
      this.loadRespostas()
    },
  },
}
</script>

<style scoped>
.chat {
  overflow-y: auto;
}
</style>