<template>
  <div>
    <div class="card border-0 mb-0 mt--9">
      <div class="card-body">
        <h3>Cadastros</h3>
        <p class="mt-3">Clique no Cadastros que deseja acessar</p>
        <div class="list-group mt-4">
          <div v-for="contrato in contratos" :key="contrato.id" class="list-group-item list-group-item-action"
               @click="onLogin(contrato)">
            <div><strong>{{ contrato.nome }}</strong></div>
            <div>{{ contrato.endereco }}</div>
            <div>{{ contrato.bairro }} - {{ contrato.cidade }}/{{ contrato.uf }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginCadastro",
  data: function () {
    return {
      data: {},
      contratos: {},
    }
  },
  created() {
    this.data = this.$route.params.data
    this.contratos = this.$route.params.contratos

    if (this.contratos === undefined || this.contratos.length === 0) {
      this.$router.push('login')
    }
  },
  methods: {
    onLogin: function (contrato) {
      let data = this.data
      data['idCliente'] = contrato.id
      this.$parent.authenticate(data)
    },
  },
}
</script>
