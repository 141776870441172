<template>
  <div v-if="isShow">
    <b-button :style="$parent.$parent.$parent.styleBgDefault" size="sm" type="button" variant="default" @click="shown">
      Notificar Pagamento
    </b-button>
    <b-modal id="modal-notificacao" ref="modal" cancel-title="Cancelar" ok-title="Confirmar" title="Nofificar Pagamento"
             @ok="confirmar">
      Confirmo pagamento(s) da(s) pendência(s).
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "NotificacaoPagamento",
  props: ['isShow'],
  methods: {
    shown: function (e) {
      e.preventDefault()
      this.$refs['modal'].show()
      this.$emit('shown')
    },
    confirmar: function () {
      this.$axios.get(process.env.VUE_APP_CENTRAL_API_URL + 'cliente/notificar-pagamento')
          .then(() => {
            this.$refs['modal'].hide()
            this.$parent.$emit('success')
          })
          .catch(() => {
            this.$refs['modal'].hide()
            this.$parent.$emit('error', 'Falha ao notificar pagamento. Tente novamente.')
          })
    },
  },
}
</script>

<style scoped>

</style>