<template>
  <div>
    <b-alert variant="danger" show v-if="error">{{ error }}</b-alert>
    <div v-else>
      <b-alert variant="danger" show v-if="errorScreen">{{ errorScreen }}</b-alert>
      <b-card v-if="isAberto">
        <textarea v-model="fieldMotivoSolitacao" rows="4" class="form-control mb-3"
                  placeholder="Motivo da Solicitação"></textarea>
        <div class="btn btn-primary" :class="{disabled: isLoadStore}" @click="clickAbrirChamado">
          <i class="fa fa-refresh fa-spin" v-if="isLoadStore"></i>
          Abrir Chamados
        </div>
      </b-card>

      <b-card>
        <b-card class="mb-0" v-if="chamados.length === 0">Nenhum Chamado para Visualização.</b-card>
        <b-card v-for="chamado in chamados" :key="chamado.id" v-else>
          <table class="table">
            <tr>
              <td>Protocolo</td>
              <td>{{ chamado.protocolo }}</td>
            </tr>
            <tr>
              <td>Motivo</td>
              <td>{{ chamado.descricao }}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                <div class="badge badge-warning" v-if="chamado.status == 0">Aberto</div>
                <div class="badge badge-primary" v-else>Finalizado</div>
              </td>
            </tr>
            <tr v-if="$root.empresa.configs.chamados_resposta">
              <td colspan="2">
                <chamados-respostas :id-suporte="chamado.id" :is-aberto="!chamado.status"></chamados-respostas>
              </td>
            </tr>
          </table>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import ChamadosRespostas from "@/components/ChamadosRespostas";

export default {
  name: "VisualizarChamados",
  components:{
    ChamadosRespostas
  },
  data: function () {
    return {
      error: null,
      errorScreen: null,
      isLoadStore: false,
      fieldMotivoSolitacao: '',

      chamados: [
        // {"id": 1120201, "protocolo": "20200903124251", "descricao": "Chamado aberto pelo Chatbot!\r\n\r\nProtocolo de atendimento: 20200903124251\r\nContato: 21993022915", "status": 0, "respostas_status": 0}
      ],
      isAberto: false,
    }
  },
  created() {
    this.$parent.$emit('loading')
    this.loadChamados()
    this.$root.$emit('breadcrumb.push', 'Visualizar Chamados', {
      name: 'chamados',
    })
  },
  methods: {
    clickAbrirChamado: function () {
      this.errorScreen = null
      this.isLoadStore = true
      if (this.fieldMotivoSolitacao.length > 5) {
        this.$axios.post(process.env.VUE_APP_CENTRAL_API_URL + 'chamados', {
          descricao: this.fieldMotivoSolitacao,
        })
            .then(() => {
              this.loadChamados()
              this.fieldMotivoSolitacao = ''
              this.isLoadStore = false
            })
            .catch(() => {
              this.errorScreen = 'Falha ao abrir chamado. Tente novamente.'
              this.isLoadStore = false
            })
      } else {
        this.errorScreen = 'Favor, informe o motivo do atendimento.'
        this.isLoadStore = false
      }
    },
    loadChamados: function () {
      this.$axios.get(process.env.VUE_APP_CENTRAL_API_URL + 'chamados')
          .then(res => {
            this.chamados = res.data.chamados
            this.isAberto = res.data.is_criar_novo
          })
          .catch((err) => {
            if (err.response.status === 400 && err.response.data.message) {
              this.error = err.response.data.message
              return
            }

            this.$router.push('home')
          })
          .finally(() => {
            this.$parent.$emit('loaded')
          })
    },
  }
}
</script>

<style scoped>
table td {
  white-space: initial;
}
</style>