<template>
  <div>
    <b-alert variant="danger" show v-if="error">{{ error }}</b-alert>
    <b-card-group columns v-else>
      <b-card v-if="daily" title="Diário">
        <b-card-img :src="daily"></b-card-img>
      </b-card>
      <b-card v-if="weekly" title="Semanal">
        <b-card-img :src="weekly"></b-card-img>
      </b-card>
      <b-card v-if="monthly" title="Mensal">
        <b-card-img :src="monthly"></b-card-img>
      </b-card>
      <b-card v-if="yearly" title="Anual">
        <b-card-img :src="yearly"></b-card-img>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
export default {
  name: "GraficoConsumo",
  data: function () {
    return {
      error: null,

      daily: null,
      weekly: null,
      monthly: null,
      yearly: null,
    }
  },
  created() {
    this.$parent.$emit('loading')
    this.$axios.get(process.env.VUE_APP_CENTRAL_API_URL + 'cliente/grafico-consumo')
        .then(res => {
          this.daily = res.data.daily
          this.weekly = res.data.weekly
          this.monthly = res.data.monthly
          this.yearly = res.data.yearly
        })
        .catch((err) => {
          if (err.response.status === 400 && err.response.data.message) {
            this.error = err.response.data.message
            return
          }

          this.$router.push('home')
        })
        .finally(() => {
          this.$parent.$emit('loaded')
        })
    this.$root.$emit('breadcrumb.push', 'Gráfico de Consumo', {
      name: 'grafico-consumo',
    })
  },
}
</script>

<style scoped>

</style>