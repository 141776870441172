<template>
  <div>
    <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
    <b-card-group v-if="!$parent.isLoad && !error" columns>
      <b-card body-class="table-responsive p-0">
        <template v-slot:header>Faturas</template>
        <faturas :boletos="boletos"></faturas>
      </b-card>

      <b-card body-class="table-responsive p-0">
        <template v-slot:header>Faturas Pagas</template>
        <faturas :boletos="boletosPagos" :is-pago="true"></faturas>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Faturas from "@/components/Faturas";

export default {
  name: "Cobrancas",
  components: {Faturas},
  data: function () {
    return {
      error: null,

      boletos: [
        // {id: 350448, codigo: 288792, data_emissao: "2017-12-29", data_pagamento: "2018-01-02", data_vencimento: "2017-12-29", valor: "0.13", valor_pago: "0.13", data_emissao_nota_fiscal: null, nota_fiscal: null, link: ""}
      ],
      boletosPagos: [
        // {id: 350448, codigo: 288792, data_emissao: "2017-12-29", data_pagamento: "2018-01-02", data_vencimento: "2017-12-29", valor: "0.13", valor_pago: "0.13", data_emissao_nota_fiscal: null, nota_fiscal: null, link: ""}
      ],
    }
  },
  created() {
    this.$parent.$emit('loading')
    this.$axios.get(process.env.VUE_APP_CENTRAL_API_URL + 'financeiros/faturas')
        .then(res => {
          this.$parent.$emit('loaded')
          this.boletos = res.data.boletos
          this.boletosPagos = res.data.boletosPagos
        })
        .catch(err => {
          if (err.response.status === 403) {
            this.error = 'Falha no carregamento das informações.'
          } else {
            this.$router.push('home')
          }
        })
        .finally(() => {
          this.$parent.$emit('loaded')
        })
    this.$root.$emit('breadcrumb.push', 'Históricos de Cobranças', {
      name: 'cobrancas',
    })
  },
  methods: {
    urlBoleto: function (boleto) {
      return 'https://link.receitanet.net/boleto/' + boleto.id
    },
  },
}
</script>

<style scoped>
table td, table th {
  border-top: none;
}
</style>