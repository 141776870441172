<template>
  <div>
    <b-card class="mt--9" title="Validação da Contrassenha">
      <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>

      <div>
        <label>Informe sua Contrassenha</label>
        <div class="form-group row">
          <input ref="dig1" v-model="dig1" class="form-control ml-3 mr-2 col-2" maxlength="1" size="1" type="text">
          <input ref="dig2" v-model="dig2" class="form-control mr-2 col-2" maxlength="1" size="1" type="text">
          <input ref="dig3" v-model="dig3" class="form-control mr-2 col-2" maxlength="1" size="1" type="text">
          <input ref="dig4" v-model="dig4" class="form-control mr-2 col-2" maxlength="1" size="1" type="text">
        </div>
      </div>
      <b-button ref="button" class="mt-4" variant="primary" @click="onValidarContrassenha">Confirmar</b-button>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ContrassenhaValidar",
  data: function () {
    return {
      error: false,

      dig1: '',
      dig2: '',
      dig3: '',
      dig4: '',
    }
  },
  created() {
    if (this.$route.params.validar === undefined) {
      this.$router.push('login')
    }
  },
  mounted() {
    this.$refs['dig1'].focus()
  },
  watch: {
    dig1: function (dig1) {
      if (dig1.length === 1) {
        this.$refs['dig2'].focus()
      }
    },
    dig2: function (dig2) {
      if (dig2.length === 1) {
        this.$refs['dig3'].focus()
      } else {
        this.$refs['dig1'].focus()
      }
    },
    dig3: function (dig3) {
      if (dig3.length === 1) {
        this.$refs['dig4'].focus()
      } else {
        this.$refs['dig2'].focus()
      }
    },
    dig4: function (dig4) {
      if (dig4.length === 0) {
        this.$refs['dig3'].focus()
      } else {
        this.$refs['button'].focus()
      }
    },
  },
  methods: {
    onValidarContrassenha: function () {
      this.error = false
      let digitos = this.dig1 + this.dig2 + this.dig3 + this.dig4
      if (digitos.length !== 4) {
        this.error = 'Informe os 4 Digitos da Contrassenha.'
        return
      }

      this.$axios.post(process.env.VUE_APP_CENTRAL_API_URL + 'contrassenha', {
        contrassenha:digitos
      })
          .then(() => {
            this.$router.push('home')
          })
          .catch(() => {
            this.error = 'Contrassenha incorreta. Verifique.'
            this.dig1 = ''
            this.dig2 = ''
            this.dig3 = ''
            this.dig4 = ''
          })
    },
  },
}
</script>

<style scoped>

</style>