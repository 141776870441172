<template>
  <div>
    <b-alert v-if="success" show variant="success">{{ success }}</b-alert>
    <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
    <b-alert v-if="servidor.isManutencao" show variant="danger" style="white-space: pre-line;">
      {{ servidor.manutencaoMensagem }}
    </b-alert>

    <b-card-group v-if="!$parent.isLoad" columns>
      <b-card v-if="$root.empresa.configs['2via_boleto']" body-class="table-responsive">
        <template v-slot:header>
          <div class="row">
            <div class="col">Últimas Faturas</div>
            <div class="col text-right">
              <notificar-pagamento
                  :is-show="isFaturaVencida && isPromessaLiberado && $root.empresa.configs.notificacao_pagamento"
                  @shown="shownNotificacaoPagamento"></notificar-pagamento>
            </div>
          </div>
        </template>
        <div class="mx--4 my--3">
          <faturas :boletos="ultimasFaturas"></faturas>
        </div>
        <template v-slot:footer v-if="$root.empresa.configs.historico_pagamento">
          <router-link :style="styleButtonHistoricoPagamento()" :to="{'name': 'cobrancas'}"
                       class="btn btn-sm btn-block mt--4">
            Histórico de Pagamentos
          </router-link>
        </template>
      </b-card>

      <b-card v-if="$root.empresa.configs.chamados" :style="styleBgVisualizarChamados()"
              class="bg-default cursor-pointer"
              @click="clickChamados">
        <div class="row">
          <div class="col-4">
            <img alt="Chamados" class="img-fluid" src="../assets/chamado.png">
          </div>
          <div class="col m-auto">
            <h3 :style="styleBgVisualizarChamados()" class="text-white text-center">Visualizar Chamados</h3>
          </div>
        </div>
      </b-card>

      <b-card v-if="$root.empresa.configs.grafico_consumo" :style="styleBgGraficoConsumo()"
              class="bg-default cursor-pointer" @click="clickGraficoConsumo">
        <div class="row">
          <div class="col-4">
            <img alt="Gráfico de Consumo" class="img-fluid" src="../assets/speedometer.png">
          </div>
          <div class="col m-auto">
            <h3 :style="styleBgGraficoConsumo()" class="text-white text-center">Gráfico de Consumo</h3>
          </div>
        </div>
      </b-card>

      <b-card v-if="$root.empresa.configs.consumos_mensal">
        <template v-slot:header>Consumo Mensal</template>
        <canvas ref="consumoMensal" class="chart-canvas"></canvas>
      </b-card>

      <b-card v-if="$root.empresa.configs.planos_cobrancas" body-class="table-responsive">
        <template v-slot:header>Planos Ativos</template>
        <table class="table mx--4 my--3">
          <tr v-if="!mensalidades.length">
            <td rowspan="2">Nenhum planos ativo encontrado.</td>
          </tr>
          <tr v-for="mensalidade in mensalidades" v-else :key="mensalidade.descricao">
            <td>{{ mensalidade.descricao }}</td>
            <td class="text-right">{{ mensalidade.quantidade }}</td>
            <td class="text-right">{{
                new Intl.NumberFormat('pt-BR', {
                  style: 'decimal',
                  minimumFractionDigits: 2
                }).format(parseFloat(mensalidade.total))
              }}
            </td>
          </tr>
          <tr v-if="mensalidades.length">
            <td class="text-right" colspan="2">Total</td>
            <td class="text-right">{{
                new Intl.NumberFormat('pt-BR', {
                  style: 'decimal',
                  minimumFractionDigits: 2
                }).format(parseFloat(totalMensalidades))
              }}
            </td>
          </tr>
        </table>
      </b-card>

      <contrato v-if="$root.empresa.configs.contrato && contrato"></contrato>

      <b-card v-if="$root.empresa.configs.materiais">
        <template v-slot:header>Materiais</template>
        <table class="table mx--4 my--3">
          <tr v-if="!materiais.length">
            <td rowspan="2">Nenhum material encontrado.</td>
          </tr>
          <tr v-for="item in materiais" v-else :key="item.id">
            <td>{{ item.descricao }}</td>
            <td class="text-right">{{ item.quantidade }}</td>
          </tr>
        </table>
      </b-card>

    </b-card-group>
  </div>
</template>

<script>
import NotificarPagamento from "@/components/NotificarPagamento"
import Contrato from "@/components/Contrato"
import chartColors from '@/utils/chartColors'
import Faturas from "@/components/Faturas";

export default {
  name: "Home",
  components: {Faturas, NotificarPagamento, Contrato},
  data: function () {
    return {
      success: null,
      error: null,

      ultimasFaturas: [
        // {id: 6481422, codigo: 641826, data_vencimento: 2020 - 10 - 20, data_pagamento: null, valor: 52.94, base58: 'zdGE', url_nota_fiscal: 'https://...'}
      ],
      isFaturaVencida: false,
      isPromessaLiberado: false,
      consumoMensalLabels: [],
      consumoMensalDown: [],
      consumoMensalUp: [],
      mensalidades: [
        // {descricao: "-TOTAL-UP+DOWN-005_Mbps", valor: "52.94", quantidade: 1, total: "52.94"}
      ],
      materiais: [
        // {descricao: "Roteador", quantidade: 1}
      ],
      contrato: null,
      dadosCadastrais: null,
      servidor: {
        isManutencao: false,
        manutencaoMensagem: null
      },
    }
  },
  computed: {
    totalMensalidades: function () {
      let total = 0
      for (let mensalidade in this.mensalidades) {
        total += parseFloat(this.mensalidades[mensalidade].total) ?? 0
      }
      return total;
    },
  },
  created() {
    this.loadHome()

    this.$root.$emit('breadcrumb.reset')
    this.$on('success', function () {
      this.success = 'Notificação de Pagamento Confirmado.'
    })
    this.$on('error', function (error) {
      this.error = error
    })
  },
  updated() {
    if (this.$root.empresa.configs.consumos_mensal) {
      this.consumoMensal()
    }
  },
  methods: {
    resetAlerts: function () {
      this.error = null
      this.success = null

      this.loadHome()
    },
    shownNotificacaoPagamento: function () {
      this.error = null
      this.success = null
    },
    clickGraficoConsumo: function () {
      this.$router.push('grafico-consumo')
    },
    clickChamados: function () {
      this.$router.push('chamados')
    },
    loadHome: function () {
      this.$parent.$emit('loading')
      this.$axios.get(process.env.VUE_APP_CENTRAL_API_URL + 'cliente/resumo')
          .then(res => {
            this.$parent.$emit('loaded')
            this.ultimasFaturas = res.data.ultimasFaturas
            this.materiais = res.data.materiais
            this.mensalidades = res.data.mensalidades
            this.consumoMensalLabels = res.data.consumoMensalLabels
            this.consumoMensalDown = res.data.consumoMensalDown
            this.consumoMensalUp = res.data.consumoMensalUp
            this.isFaturaVencida = res.data.isFaturaVencida
            this.isPromessaLiberado = res.data.isPromessaLiberado
            this.contrato = res.data.contrato
            this.dadosCadastrais = res.data.dados_cadastrais
            this.servidor = res.data.servidor
            if (res.data.dados_cadastrais !== undefined && res.data.dados_cadastrais.nome !== undefined) {
              this.$root.$emit('dados_cadastrais', res.data.dados_cadastrais)
            }

            if (this.$root.empresa.configs.consumos_mensal) {
              this.consumoMensal()
            }
          })
          .catch(() => {
            this.$router.push('login')
          })
    },
    consumoMensal: function () {
      if (!this.$refs.consumoMensal) {
        return
      }
      new window.Chart(this.$refs.consumoMensal.getContext('2d'), {
        type: 'line',
        data: {
          labels: this.consumoMensalLabels,
          datasets: [{
            label: 'Download',
            backgroundColor: chartColors.green,
            borderColor: chartColors.green,
            data: this.consumoMensalDown,
            fill: false,
          }, {
            label: 'Upload',
            fill: false,
            backgroundColor: chartColors.red,
            borderColor: chartColors.red,
            data: this.consumoMensalUp,
          }]
        },
        options: {
          responsive: true,
          tooltips: {
            enabled: true,
            mode: 'index',
            position: 'nearest',
            callbacks: {
              label: function (tooltip, obj) {
                let val = parseFloat(tooltip.yLabel)
                return obj.datasets[tooltip.datasetIndex].label + ": " + val.toLocaleString() + "GB"
              }
            },
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Consumo em GB'
              }
            }],
          }
        }
      })
    },
    styleBgVisualizarChamados: function () {
      return {
        'background-color': this.$root.empresa.configs.theme_bg_visualizar_chamados + ' !important',
        'color': this.$root.detectContrast(this.$root.empresa.configs.theme_bg_visualizar_chamados) + ' !important',
      }
    },
    styleBgGraficoConsumo: function () {
      return {
        'background-color': this.$root.empresa.configs.theme_bg_grafico_consumo + ' !important',
        'color': this.$root.detectContrast(this.$root.empresa.configs.theme_bg_grafico_consumo) + ' !important',
      }
    },
    styleButtonHistoricoPagamento: function () {
      return {
        'background-color': this.$root.empresa.configs.theme_button_historico_pagamentos + ' !important',
        'color': this.$root.detectContrast(this.$root.empresa.configs.theme_button_historico_pagamentos) + ' !important',
      }
    },
  }
}
</script>

<style scoped>
table td, table th {
  border-top: none;
}

small {
  color: #66615b;
}

.icon-circle {
  border-radius: 30px;
  background-color: #5e72e4;
  width: 40px;
  height: 40px;
  line-height: 30px;
}

.icon-circle .fa {
  color: white;
  font-size: 1.5rem;
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.cursor-pointer {
  cursor: pointer;
}

.card-header, .card-footer {
  background-color: initial;
}

.card-footer {
  border-top: initial;
}
</style>