<template>
  <b-modal v-model="modalShow" title="Pagar" size="lg" body-class="row">
    <div class="col-sm-12">
      <p>
        Valor a pagar: {{ new Intl.NumberFormat("pt-BR", {style: 'currency', currency: 'BRL'}).format(boleto.valor) }}
      </p>
    </div>
    <div class="col-sm-12" style="margin-bottom: 15px;">
      <b-button-group>
        <b-button :href="urlBoleto(boleto)" variant="primary" target="_blank">Imprimir</b-button>
        <b-button :href="boleto.link_fatura_pdf" variant="primary" target="_blank">Download</b-button>
      </b-button-group>
    </div>
    <div class="col-sm-12">
      <b-form-group v-if="boleto.linha_digitavel">
        <b-form-input v-model="boleto.linha_digitavel"></b-form-input>
      </b-form-group>
      <b-button v-if="boleto.linha_digitavel" variant="primary" id="pix-clipboard"
                v-clipboard:copy="boleto.linha_digitavel"
                v-clipboard:success="onCopyPix"
                v-clipboard:error="onErrorPix">Copiar linha digitável do boleto
      </b-button>
      <p v-if="showSuccess">Copiado com Sucesso!</p>
      <p v-if="showError">Erro ao copiar!</p>
    </div>
    <template #modal-footer>
      <div>
        <b-button @click="clickPagarPix(boleto)" variant="success">Pagar com Pix</b-button>
        <b-button @click="modalShow=false" variant="default">Fechar</b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: "Fatura",
  data() {
    return {
      boleto: Array,
      modalShow: false,
      showSuccess: false,
      showError: false,
    }
  },
  mounted() {
    this.$on('show', function (boleto) {
      this.showModal(boleto)
    })
  },
  methods: {
    clickPagarPix: function (boleto) {
      this.modalShow = false
      this.$parent.$refs['fatura-pix'].$emit('show', boleto)
    },
    urlBoleto: function (boleto) {
      return 'https://link.receitanet.net/boleto/' + boleto.id
    },
    showModal(boleto) {
      this.boleto = boleto
      this.modalShow = true
    },
    onCopyPix() {
      this.showSuccess = true
      this.showError = false
      setTimeout(() => this.showSuccess = false, 5000)
    },
    onErrorPix() {
      this.showSuccess = false
      this.showError = true
      setTimeout(() => this.showError = false, 5000)
    },
  }
}
</script>

