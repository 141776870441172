<template>
  <div></div>
</template>

<script>
export default {
  // https://ih5g.centralassinante.local/?cpfcnpj=011.565.957-97
  name: "Index",
  created() {
    let params = this.transformToAssocArray(window.location.search.substr(1))
    this.$router.push({
      name: 'login', params
    })
  },
  methods: {
    transformToAssocArray: function (prmstr) {
      var params = {};
      var prmarr = prmstr.split("&");
      for (var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split("=");
        params[tmparr[0]] = tmparr[1];
      }
      return params;
    },
  },
}
</script>

<style scoped>

</style>