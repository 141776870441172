<template>
  <div>
    <b-card title="Contrassenha">
      <b-alert variant="danger" show v-if="error">{{ error }}</b-alert>
      <b-alert variant="success" show v-if="success">{{ success }}</b-alert>

      <div v-if="isVerificar" class="mb-5">
        <label>Informe a Contrassenha Anterior</label>
        <div>
          <div class="form-group row">
            <input type="text" class="form-control mr-2 ml-3 col-1" maxlength="1" size="1" v-model="verificarDig1"
                   ref="verificarDig1">
            <input type="text" class="form-control mr-2 col-1" maxlength="1" size="1" v-model="verificarDig2"
                   ref="verificarDig2">
            <input type="text" class="form-control mr-2 col-1" maxlength="1" size="1" v-model="verificarDig3"
                   ref="verificarDig3">
            <input type="text" class="form-control mr-2 col-1" maxlength="1" size="1" v-model="verificarDig4"
                   ref="verificarDig4">
          </div>
        </div>
      </div>

      <label v-if="isForcaCadastrar">Cadastre sua Contrassenha para Acessar</label>
      <label v-else>Informe a nova Contrassenha</label>
      <div>
        <div class="form-group row">
          <input type="text" class="form-control mr-2 ml-3 col-1" maxlength="1" size="1" v-model="dig1" ref="dig1">
          <input type="text" class="form-control mr-2 col-1" maxlength="1" size="1" v-model="dig2" ref="dig2">
          <input type="text" class="form-control mr-2 col-1" maxlength="1" size="1" v-model="dig3" ref="dig3">
          <input type="text" class="form-control mr-2 col-1" maxlength="1" size="1" v-model="dig4" ref="dig4">
        </div>
      </div>
      <div><small>Essa contrassenha será usada sempre que acessar sua central do assinante.</small></div>

      <b-button class="mt-4" variant="primary" @click="onChangeContrassenha">Confirmar</b-button>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Contrassenha",
  data: function () {
    return {
      error: false,
      success: false,
      isVerificar: false,
      isForcaCadastrar: false,

      verificarDig1: '',
      verificarDig2: '',
      verificarDig3: '',
      verificarDig4: '',

      dig1: '',
      dig2: '',
      dig3: '',
      dig4: '',
    }
  },
  watch: {
    verificarDig1: function (verificarDig1) {
      if (verificarDig1.length === 1) {
        this.$refs['verificarDig2'].focus()
      }
    },
    verificarDig2: function (verificarDig2) {
      if (verificarDig2.length === 1) {
        this.$refs['verificarDig3'].focus()
      } else {
        this.$refs['verificarDig1'].focus()
      }
    },
    verificarDig3: function (verificarDig3) {
      if (verificarDig3.length === 1) {
        this.$refs['verificarDig4'].focus()
      } else {
        this.$refs['verificarDig2'].focus()
      }
    },
    verificarDig4: function (verificarDig4) {
      if (verificarDig4.length === 0) {
        this.$refs['verificarDig3'].focus()
      }
    },
    dig1: function (dig1) {
      if (dig1.length === 1) {
        this.$refs['dig2'].focus()
      }
    },
    dig2: function (dig2) {
      if (dig2.length === 1) {
        this.$refs['dig3'].focus()
      } else {
        this.$refs['dig1'].focus()
      }
    },
    dig3: function (dig3) {
      if (dig3.length === 1) {
        this.$refs['dig4'].focus()
      } else {
        this.$refs['dig2'].focus()
      }
    },
    dig4: function (dig4) {
      if (dig4.length === 0) {
        this.$refs['dig3'].focus()
      }
    },
  },
  created() {
    this.$parent.$emit('loaded')
    this.$root.$emit('breadcrumb.push', 'Contrassenha', {
      name: 'contrassenha',
    })

    if (this.$route.params.is_definir_contrassenha === true) {
      this.isForcaCadastrar = true
    }
    if (this.$route.params.is_contrassenha === true) {
      this.isVerificar = true
    }
    if (this.$root.isContrassenha) {
      this.isVerificar = true
    }
  },
  methods: {
    onChangeContrassenha: function () {
      this.error = false
      this.success = false

      let digitos = this.dig1 + this.dig2 + this.dig3 + this.dig4
      if (digitos.length !== 4) {
        this.error = 'Informe os 4 Digitos da Contrassenha.'
        return
      }
      let verificar = this.verificarDig1 + this.verificarDig2 + this.verificarDig3 + this.verificarDig4
      let data = {contrassenha: digitos}
      if (verificar.length === 4) {
        data['old_contrassenha'] = verificar
      }
      this.$axios.put(process.env.VUE_APP_CENTRAL_API_URL + 'contrassenha', data)
          .then(() => {
            this.success = 'Contrassenha Cadastrada com Sucesso.'
            this.dig1 = ''
            this.dig2 = ''
            this.dig3 = ''
            this.dig4 = ''
            this.verificarDig1 = ''
            this.verificarDig2 = ''
            this.verificarDig3 = ''
            this.verificarDig4 = ''

            if (this.isForcaCadastrar) {
              this.$router.push('home')
            }
          })
          .catch(err => {
            this.error = err.response.data.message
          })
    },
  },
}
</script>

<style scoped>

</style>