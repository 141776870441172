<template>
  <b-modal v-model="modalShow" title="Pagar com Pix" size="lg" body-class="row">
    <div class="col-sm-7 col-md-6">
      <p>
        <img src="../assets/pix_logo.png" alt="Logotipo do Pix" width="30">
        Valor a pagar: {{ new Intl.NumberFormat("pt-BR", {style: 'currency', currency: 'BRL'}).format(boleto.valor) }}
      </p>
      <p>Escaneie o QR code:</p>
      <p class="text-center">
        <img class="img-fluid" v-if="qrcode" :src="qrcode" alt="QR code do Pix">
      </p>

      <p>Se preferir, você pode pegar copiando e colando o seguinte código</p>
      <b-form-group>
        <b-form-input v-model="boleto.qrcode"></b-form-input>
      </b-form-group>
      <b-button variant="primary" id="pix-clipboard"
                v-clipboard:copy="boleto.qrcode"
                v-clipboard:success="onCopyPix"
                v-clipboard:error="onErrorPix">Copiar Pix
      </b-button>
      <p v-if="showPixSuccess">Copiado com Sucesso!</p>
      <p v-if="showPixError">Erro ao copiar!</p>
    </div>
    <div class="col-sm-5 col-md-6">
      <p class="d-sm-none"><br></p>
      <p>1 - Acesse o app ou site do seu banco;</p>
      <p>2 - Busque a opção de pagar com Pix;</p>
      <p>3 - Leia o QR code ou cole o código Pix; e</p>
      <p>4 - Pronto! Você verá a confirmação do pagamento</p>
    </div>
    <template #modal-footer>
      <div>
        <b-button @click="modalShow=false" variant="default">Fechar</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      boleto: Array,
      modalShow: false,
      qrcode: false,
      showPixSuccess: false,
      showPixError: false,
    }
  },
  mounted() {
    this.$on('show', function (boleto) {
      this.showModal(boleto)
    })
  },
  methods: {
    showModal(boleto) {
      this.boleto = boleto
      this.qrcode = false
      if (boleto.qrcode_img !== undefined && boleto.qrcode_img.length) {
        this.qrcode = boleto.qrcode_img
      }
      this.modalShow = true
    },
    onCopyPix() {
      this.showPixSuccess = true
      this.showPixError = false
      setTimeout(() => this.showPixSuccess = false, 5000)
    },
    onErrorPix() {
      this.showPixSuccess = false
      this.showPixError = true
      setTimeout(() => this.showPixError = false, 5000)
    },
  },
}
</script>
<style scoped>
li {
  list-style: none;
}
</style>