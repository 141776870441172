<template>
  <div>
    <b-card class="bg-default cursor-pointer" @click="clickContrato" :style="$parent.$parent.$parent.styleBgDefault">
      <div class="row">
        <div class="col-4">
          <img class="img-fluid" src="../assets/contract.png" alt="Contrato">
        </div>
        <div class="col m-auto">
          <p class="display-4 text-white text-center">Contrato</p>
        </div>
      </div>
    </b-card>

    <b-modal ref="modal" title="Contrato" footer-class="d-none">
      <div class="container" v-if="isLoad">
        <div class="row row-cols-1">
          <div class="col text-center">
            <span class="fa fa-refresh fa-spin"></span>
          </div>
          <div class="col text-center">
            <span>Carregando...</span>
          </div>
        </div>
      </div>
      <div v-else>
        <a :href="contrato" target="_blank" class="btn btn-primary">Abrir Contrato</a>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Contrato",
  data: function () {
    return {
      isLoad: true,
      contrato: null,
    }
  },
  methods: {
    clickContrato: function () {
      this.$refs['modal'].show()

      this.download()
    },
    download: function () {
      this.$axios.get(process.env.VUE_APP_CENTRAL_API_URL + 'cliente/contrato', {
        responseType: 'blob',
      })
          .then(res => {
            this.isLoad = false
            this.contrato = window.URL.createObjectURL(res.data)
          })
          .catch(() => {
            this.$refs['modal'].hide()
            this.$parent.$emit('error', 'Falha ao pegar contrato. Tente novamente.')
          })
    },
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>